import axios from 'axios'

const api = axios.create({
    //baseURL: 'http://localhost:53755'
    baseURL: 'https://api.housebots.com.br'
});

api.interceptors.request.use(config => {
    return config
}, error => Promise.reject(error))

api.interceptors.response.use(res => {
    return res
}, error => Promise.reject(error))

export default {
    methods: {
        async connServ(url, tpreq, dados) {
            //corrigindo headers
            let responseApi = { bstatus: false, dados: {}, msg: "" };
            var xTokenAuth = '';
            let user = JSON.parse(localStorage.getItem('userDados'));
            if (user) xTokenAuth = user.token;
            api.defaults.headers.common['Authorization'] = 'Bearer ' + xTokenAuth;
            api.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            api.defaults.headers.common['Content-Type'] = 'application/json';

            console.log("connServ conectando em: ", url, " token: ", xTokenAuth);

            if (tpreq == 'get') {
                await api.get(url).then((response) => {
                    responseApi.bstatus = true;
                    responseApi.dados = response.data;
                }).catch((error) => {
                    responseApi.bstatus = false;
                    responseApi.msg = error;
                    console.log('ERRO GET: ', error);
                    if (error.response) {
                        console.log('error data=', error.response.data);
                        console.log('error status=', error.response.status);
                        console.log('error headers=', error.response.headers);
                        if (error.response.status == 401) {
                            console.log("Enviando para login...");
                            this.$router.push({ name: "login" });
                        }
                    }
                });
            }

            if (tpreq == 'post') {
                console.log("Postando em ", dados, " ", url)
                await api.post(url, dados).then((response) => {
                    responseApi.bstatus = true;
                    responseApi.dados = response.data;
                }).catch((error) => {
                    responseApi.bstatus = false;
                    responseApi.msg = error;
                    console.log('ERRO POST: ', error);
                    if (error.response) {
                        console.log('error data=', error.response.data);
                        console.log('error status=', error.response.status);
                        console.log('error headers=', error.response.headers);
                        if (error.response.status == 401) {
                            console.log("Enviando para login...");
                            this.$router.push({ name: "login" });
                        }
                    }
                });
            }

            return responseApi;
        }
    }
}

